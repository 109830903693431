<template>
  <div
    class="flex justify-between sm:px-10 py-8 text-sm sm:text-base pointer-events-none"
  >
    <div
      class="flex flex-wrap justify-center sm:justify-start items-center mt-10 sm:w-3/5 lg:w-full"
      v-if="showInitialLogo"
    >
      <LogoPalauRobert class="fill-current h-10 mt-2 mr-8"></LogoPalauRobert>
      <LogoCatalunya2030
        class="fill-current mt-4 mr-8"
        style="height: 1.7rem"
      ></LogoCatalunya2030>
      <LogoGeneralitat class="fill-current h-6 mt-4 mr-8"></LogoGeneralitat>
      <LogoManresaAjuntament
        class="fill-current mt-4 mr-8"
        style="height: 2.3rem"
      ></LogoManresaAjuntament>
      <LogoManresaCasino class="fill-current h-8 mt-3 mr-8"></LogoManresaCasino>
    </div>
    <div class="absolute left-0 bottom-0 mb-8 sm:right-0 ml-8 sm:ml-0">
      <ul class="inline-flex items-center space-x-2">
        <li
          v-for="locale in localesNotActive"
          :key="locale"
          :class="[showInitialLogo ? 'hidden xl:block' : 'block']"
          class=""
        >
          <button
            @click="changeLocale(locale)"
            class="w-14 py-1 uppercase border border-white rounded-full hover:bg-white hover:text-blue-600 transition duration-300 ease-in-out focus:outline-none pointer-events-auto"
          >
            {{ locale }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LogoPalauRobert from "@/assets/LogoPalauRobert.svg";
import LogoCatalunya2030 from "@/assets/LogoCatalunya2030.svg";
import LogoGeneralitat from "@/assets/LogoGeneralitat.svg";
import LogoManresaAjuntament from "@/assets/LogoManresaAjuntament.svg";
import LogoManresaCasino from "@/assets/LogoManresaCasino.svg";

export default {
  props: { showInitialLogo: Boolean },
  computed: {
    localesNotActive() {
      return this.$i18n.availableLocales.filter(
        (locale) => locale != this.$i18n.locale
      );
    },
  },
  components: {
    LogoPalauRobert,
    LogoGeneralitat,
    LogoManresaAjuntament,
    LogoManresaCasino,
    LogoCatalunya2030,
  },
  methods: {
    changeLocale(locale) {
      // this.$i18n.locale = locale;
      // const html = document.documentElement; // returns the html tag
      // html.setAttribute("lang", locale);
      this.$parent.$emit("reload", locale);
    },
  },
};
</script>

<style scoped>
.w-14 {
  width: 3.5rem;
}
</style>
