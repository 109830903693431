import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/tailwind.css";
import i18n from "./i18n";

import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
