import { createMachine, assign } from "xstate";
import { v4 as uuidv4 } from "uuid";

const userDidntVote = (context) => {
  return context.userHasVoted === false;
};

const checkLocalStorage = assign(() => {
  const token = localStorage.getItem("id");
  if (token) {
    const userHasVoted = localStorage.getItem("userVoted");
    if (userHasVoted)
      return {
        userHasVoted: true,
      };
  } else {
    const newToken = uuidv4();
    localStorage.setItem("id", newToken);
  }
});

const sequenceMachine = createMachine(
  {
    id: "experience",
    initial: "landing",
    context: {
      userHasVoted: false,
    },
    states: {
      landing: {
        entry: "checkLocalStorage",
        on: {
          BEGIN: "introOne",
        },
      },
      introOne: {
        on: { NEXT: "introTwo" },
        after: {
          6000: "introTwo",
        },
      },
      introTwo: {
        on: { NEXT: "introThree" },
        after: {
          6000: "introThree",
        },
      },
      introThree: {
        on: {
          NEXT: [{ target: "form", cond: "userDidntVote" }, { target: "end" }],
        },
        after: {
          6000: [{ target: "form", cond: "userDidntVote" }, { target: "end" }],
        },
      },
      form: {
        on: {
          SUCCESS: "end",
        },
      },
      end: {
        type: "final",
      },
    },
  },
  {
    guards: {
      userDidntVote: userDidntVote,
    },
    actions: { checkLocalStorage: checkLocalStorage },
  }
);

export { sequenceMachine };
