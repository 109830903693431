<template>
  <div
    class="h-full text-white text-center flex flex-col justify-center relative bg-image"
  >
    <transition
      mode="out-in"
      appear
      enter-active-class="transition-opacity duration-1000 ease-in-out"
      leave-active-class="transition-opacity duration-700 ease-in-out"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="
          state.value != 'landing' &&
          state.value != 'form' &&
          state.value != 'end'
        "
        class="absolute left-0 right-0 bottom-0 z-50 pointer-events-none flex justify-center mb-32"
      >
        <button
          class="inline-block border border-white rounded-full py-3 px-10 hover:bg-white hover:text-blue-600 transition duration-300 ease-in-out focus:outline-none pointer-events-auto"
          @click="send('NEXT')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-right"
          >
            <line x1="5" y1="12" x2="19" y2="12"></line>
            <polyline points="12 5 19 12 12 19"></polyline>
          </svg>
        </button>
      </div>
    </transition>

    <div
      class="absolute top-0 left-0 right-0 bottom-0 bg-color opacity-50"
      style="touch-action: none; pointer-events: none"
    ></div>

    <BubbleAnimation
      class="absolute top-0 left-0 right-0 bottom-0 overflow-hidden"
      style="touch-action: none; pointer-events: none"
    ></BubbleAnimation>

    <transition
      mode="out-in"
      appear
      enter-active-class="transition-opacity duration-1000 ease-in-out"
      leave-active-class="transition-opacity duration-700 ease-in-out"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PageHeader
        class="fixed top-0 left-0 right-0"
        v-if="state.value != 'landing'"
      ></PageHeader>
    </transition>

    <!-- <transition
      enter-active-class="transition-opacity duration-300 ease-in-out"
      leave-active-class="transition-opacity duration-200 ease-in-out"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <AboutModal
        class="transform translate-3d z-40"
        v-show="showModal"
      ></AboutModal>
    </transition> -->

    <div v-if="state.value === 'landing'" class="intro">
      <div
        class="texts flex flex-col justify-center items-center px-10 max-w-full -mt-24 sm:mt-0"
      >
        <!-- First Line Catalan and Spanish -->
        <div
          class="text-intro max-w-3xl relative z-20"
          v-if="$i18n.locale != 'en'"
        >
          <img src="../assets/intro_layers/text_feminista.png" alt srcset />
        </div>

        <!-- Second Line Catalan -->
        <div
          class="text-intro -mt-6 md:-mt-16 max-w-lg px-16 lg:px-12 relative z-20"
          v-if="$i18n.locale == 'ca'"
        >
          <img
            src="../assets/intro_layers/text_havies_de_ser_ca.png"
            alt
            srcset
          />
        </div>

        <!-- Second Line Spanish -->
        <div
          class="text-intro -mt-6 md:-mt-16 max-w-lg px-16 lg:px-12 relative z-20"
          v-if="$i18n.locale == 'es'"
        >
          <img
            src="../assets/intro_layers/text_havies_de_ser_es.png"
            alt
            srcset
          />
        </div>

        <!-- First line English -->
        <div
          class="text-intro relative z-20 max-w-lg px-16 lg:px-12"
          v-if="$i18n.locale == 'en'"
        >
          <img
            src="../assets/intro_layers/text_havies_de_ser_en.png"
            alt
            srcset
          />
        </div>

        <!-- Second Line English -->
        <div
          class="text-intro max-w-3xl -mt-6 md:-mt-16 relative z-20"
          v-if="$i18n.locale == 'en'"
        >
          <img src="../assets/intro_layers/text_feminista_en.png" alt srcset />
        </div>

        <!-- Text Background Catalan and Spanish -->
        <div
          class="bg-text-intro absolute left-0 right-0 overflow-hidden z-10"
          v-if="$i18n.locale != 'en'"
        >
          <div class="-ml-20 -mr-20">
            <img
              src="../assets/intro_layers/text_bg.png"
              alt
              srcset
              class="w-full z-0 max-w-6xl mx-auto"
            />
          </div>
        </div>

        <!-- Text Background English -->
        <div
          class="bg-text-intro absolute left-0 right-0 overflow-hidden z-10"
          v-if="$i18n.locale == 'en'"
        >
          <div class="-ml-20 -mr-20">
            <img
              src="../assets/intro_layers/text_bg_en.png"
              alt
              srcset
              class="w-full z-0 max-w-6xl mx-auto"
            />
          </div>
        </div>

        <!-- Text Shine Catalan and Spanish -->
        <div
          class="shine-intro absolute ml-16 -mt-12 md:ml-20 md:-mt-16 z-40 blend-screen"
          v-if="$i18n.locale != 'en'"
        >
          <div class="max-w-6xl">
            <img src="../assets/intro_layers/text_shine.png" alt srcset />
          </div>
        </div>

        <!-- Text Shine English -->
        <div
          class="shine-intro absolute ml-32 -mt-4 md:ml-48 md:mt-8 z-40 blend-screen"
          v-if="$i18n.locale == 'en'"
        >
          <div class="max-w-6xl">
            <img src="../assets/intro_layers/text_shine.png" alt srcset />
          </div>
        </div>

        <div
          class="absolute left-0 right-0 top-0 bottom-0 overflow-hidden flex justify-center items-center sm:mt-10 z-0"
        >
          <div class="-ml-32 mt-24 sm:mt-64 horizontal-shine origin-top-right">
            <img
              src="../assets/intro_layers/horizontal_shine.png"
              alt
              srcset
              class="w-full z-0 max-w-6xl mx-auto"
            />
          </div>
        </div>

        <!-- Intro sticker -->
        <div
          class="intro-sticker mt-4 md:mt-0 absolute left-0 right-0 top-0 bottom-0 overflow-hidden flex justify-center items-center z-30"
        >
          <svg
            class="w-2/3 sm:w-1/2 max-w-sm"
            xmlns="http://www.w3.org/2000/svg"
            overflow="visible"
            viewBox="0 0 480 240"
          >
            <ellipse cx="50%" cy="50%" rx="240" ry="120" fill="#F4E90D" />
            <text fill="#E42925">
              <tspan
                x="50%"
                y="30%"
                dominant-baseline="middle"
                text-anchor="middle"
                class="uppercase text-3xl font-semibold"
              >
                {{ $t("intro.sticker.text-1") }}
              </tspan>
              <tspan
                x="50%"
                y="52%"
                dominant-baseline="middle"
                text-anchor="middle"
                class="uppercase text-6xl font-semibold"
              >
                {{ $t("intro.sticker.text-2") }}
              </tspan>
              <tspan
                x="50%"
                y="73%"
                dominant-baseline="middle"
                text-anchor="middle"
                class="uppercase text-3xl font-semibold"
              >
                {{ $t("intro.sticker.text-3") }}
              </tspan>
            </text>
          </svg>
        </div>

        <!-- Place sticker -->
        <svg
          class="place-sticker w-2/3 sm:w-1/2 max-w-sm absolute left-0 top-0 z-30"
          xmlns="http://www.w3.org/2000/svg"
          overflow="visible"
          viewBox="0 0 480 240"
        >
          <ellipse cx="50%" cy="50%" rx="240" ry="120" fill="#F4E90D" />
          <g>
            <text fill="#E42925">
              <tspan
                x="50%"
                y="34%"
                dominant-baseline="middle"
                text-anchor="middle"
                class="text-4xl font-semibold"
              >
                {{ $t("intro.sticker_place.title") }}
              </tspan>
            </text>
            <g fill="#E42925" transform="translate(0,105)">
              <text
                x="50%"
                :y="`${30 * i + 18}`"
                dominant-baseline="middle"
                text-anchor="middle"
                class="text-2xl font-semibold"
                v-for="(text, i) in $t('intro.sticker_place.texts')"
                :key="i"
              >
                {{ text }}
              </text>
            </g>
          </g>
        </svg>

        <!-- Intro button -->
        <div
          class="absolute left-0 right-0 bottom-0 mb-40 md:mb-32 overflow-hidden flex justify-center items-center z-index-top pointer-events-none"
        >
          <button
            @click="introLeave()"
            class="intro-button cursor-pointer text-xl py-3 px-16 border border-white rounded-full hover:bg-white hover:text-blue-600 transition duration-300 ease-in-out focus:outline-none pointer-events-auto opacity-0"
          >
            {{ $t("enter_btn") }}
          </button>
        </div>
      </div>

      <div
        class="flowers absolute top-0 left-0 right-0 bottom-0 overflow-hidden z-0"
      >
        <img
          src="../assets/intro_layers/flower.png"
          alt
          srcset
          class="hidden sm:block flower-right absolute bottom-0 right-0 max-w-xs md:max-w-md xl:max-w-xl 2xl:max-w-2xl -mb-24 -mr-16 xl:-mb-40 xl:-mr-40 z-10"
        />
        <img
          src="../assets/intro_layers/white-grad.png"
          alt
          srcset
          class="hidden sm:block flower-right absolute bottom-0 right-0 max-w-3xl md:max-w-6xl xl:max-w-7xl 2xl:max-w-8xl -mr-20 md:-mr-40 -mb-10"
        />
        <img
          src="../assets/intro_layers/flower.png"
          alt
          srcset
          class="flower-left absolute top-0 left-0 max-w-xs md:max-w-md xl:max-w-xl 2xl:max-w-2xl -mt-24 -ml-16 xl:-ml-40 xl:-mt-40 transform rotate-180 z-10"
        />

        <img
          src="../assets/intro_layers/white-grad.png"
          alt
          srcset
          class="flower-left absolute top-0 left-0 max-w-3xl md:max-w-6xl xl:max-w-7xl 2xl:max-w-8xl -ml-20 md:-ml-40 -mt-10 transform rotate-180"
        />
      </div>
      <div
        class="shines absolute top-0 left-0 right-0 bottom-0 overflow-hidden hidden md:block"
      >
        <img
          src="../assets/intro_layers/vertical_shine.png"
          alt
          srcset
          class="vertical-shine absolute top-0 left-0 -ml-20 origin-top"
        />
      </div>
    </div>

    <div
      v-if="state.value != 'landing'"
      class="px-10 max-w-4xl mx-auto w-full relative z-10"
    >
      <!-- <button @click="step++" v-if="step <= 4">Next</button> -->
      <div class="text-xl sm:text-2xl md:text-3xl lg:px-4">
        <transition
          mode="out-in"
          appear
          enter-active-class="transition-opacity duration-1000 ease-in-out"
          leave-active-class="transition-opacity duration-700 ease-in-out"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-if="state.value === 'introOne'" key="1">
            <p>{{ $t("slides[0].text") }}</p>
          </div>
          <div v-else-if="state.value === 'introTwo'" key="2">
            <p>{{ $t("slides[1].text") }}</p>
          </div>
          <div v-else-if="state.value === 'introThree'" key="3">
            <p>
              {{ $t("slides[2].text") }}
              <br class="hidden sm:block" />
              {{ $t("slides[2].text2") }}
            </p>
          </div>

          <!-- mb-20 -->
          <div
            v-else-if="state.value === 'form'"
            key="4"
            class="comment-form-margin"
          >
            <p class="mt-10">
              {{ $t("slides[2].text") }}
              <br class="hidden sm:block" />
              {{ $t("slides[2].text2") }}
            </p>
            <div class="mt-10">
              <CommentForm @step="send('SUCCESS')"></CommentForm>
              <div class="max-w-3xl mx-auto px-8 mt-10 sm:mt-10">
                <p
                  class="text-sm sm:text-base md:text-lg hidden show-bigger-height"
                >
                  {{ $t("slides[2].text3") }}
                </p>
              </div>

              <!-- <div v-else>
                You have voted
              </div>-->
            </div>
          </div>
          <div v-else-if="state.value === 'end'" key="5">
            <p>
              {{ $t("slides[3].text") }}
              <br />
              {{ $t("slides[3].text2") }}
            </p>
            <div
              class="-mx-8 sm:mx-0 flex justify-center flex-wrap items-center space-x-8 mt-10"
            >
              <LogoPalauRobert
                class="fill-current h-10 sm:h-12 mt-2"
              ></LogoPalauRobert>

              <LogoCatalunya2030
                class="fill-current h-6 sm:h-8 mt-3"
              ></LogoCatalunya2030>

              <LogoGeneralitat
                class="fill-current h-6 sm:h-8 mt-3"
              ></LogoGeneralitat>

              <LogoManresaAjuntament
                class="fill-current h-10 sm:h-12 mt-3"
              ></LogoManresaAjuntament>

              <LogoManresaCasino
                class="fill-current h-10 sm:h-12 mt-2"
              ></LogoManresaCasino>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <MobileHomeLanguageSwitcher
      v-if="showInitialLogo === true"
      class="fixed z-50 right-0 top-0"
    ></MobileHomeLanguageSwitcher>

    <transition
      mode="out-in"
      appear
      enter-active-class="transition-opacity duration-1000 ease-in-out"
      leave-active-class="transition-opacity duration-700 ease-in-out"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PageFooter
        :showInitialLogo="showInitialLogo"
        v-if="showInitialLogo"
        class="fixed z-50 bottom-0 left-0 right-0 hidden show-bigger-height"
      ></PageFooter>
    </transition>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import BubbleAnimation from "@/components/BubbleAnimation.vue";
import MobileHomeLanguageSwitcher from "@/components/MobileHomeLanguageSwitcher.vue";
// import AboutModal from "@/components/AboutModal.vue";
import CommentForm from "@/components/CommentForm.vue";

import LogoPalauRobert from "@/assets/LogoPalauRobert.svg";
import LogoGeneralitat from "@/assets/LogoGeneralitat.svg";
import LogoCatalunya2030 from "@/assets/LogoCatalunya2030.svg";

// Logos Canviants
import LogoManresaAjuntament from "@/assets/LogoManresaAjuntament.svg";
import LogoManresaCasino from "@/assets/LogoManresaCasino.svg";

import anime from "animejs/lib/anime.es.js";

import { sequenceMachine } from "@/store/machine.js";
import { useMachine } from "@xstate/vue";

import { ref } from "@vue/composition-api";

export default {
  components: {
    PageHeader,
    PageFooter,
    BubbleAnimation,
    CommentForm,
    MobileHomeLanguageSwitcher,
    LogoPalauRobert,
    LogoGeneralitat,
    LogoCatalunya2030,
    LogoManresaAjuntament,
    LogoManresaCasino,
    // AboutModal
  },
  data() {
    return {
      showModal: false,
    };
  },
  setup() {
    const { state, send } = useMachine(sequenceMachine);

    const showInitialLogo = ref(true);

    const introLeave = () => {
      var textElements = document.querySelectorAll(`.text-intro`);

      showInitialLogo.value = false;

      anime({
        easing: "easeOutQuad",
        targets: ".flower-right, .flower-left",
        opacity: 0,
        duration: 250,
      });

      anime({
        easing: "easeOutQuad",
        targets: ".horizontal-shine, .vertical-shine",
        opacity: 0,
        duration: 300,
      });

      anime({
        easing: "easeOutQuad",
        targets: ".intro-sticker",
        opacity: 0,
        duration: 600,
      });

      anime({
        easing: "easeOutQuad",
        targets: ".place-sticker",
        opacity: 0,
        duration: 600,
      });

      anime
        .timeline({ easing: "easeOutQuad" })
        .add({
          targets: ".bg-text-intro",
          opacity: 0,
          duration: 500,
          delay: 150,
        })
        .add(
          {
            targets: textElements[0],
            opacity: 0,
            duration: 800,
          },
          "-=400"
        )
        .add(
          {
            targets: ".shine-intro",
            opacity: 0,
            duration: 800,
          },
          "-=800"
        )
        .add(
          {
            targets: ".intro-button",
            opacity: 0,
            duration: 200,
          },
          "-=1600"
        )
        .add(
          {
            targets: textElements[1],
            opacity: 0,
            duration: 800,
            complete: () => {
              send("BEGIN");
            },
          },
          "-=700"
        );
    };

    return {
      state,
      send,
      introLeave,
      showInitialLogo,
    };
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    urlParams.get("lang")
      ? (this.$i18n.locale = urlParams.get("lang"))
      : (this.$i18n.locale = "ca");

    this.$router.push("/");

    window.onload = this.introEnter();

    this.$on("reload", (lang) => {
      window.location.assign("/?lang=" + lang);
    });
  },
  methods: {
    introEnter() {
      var textElements = document.querySelectorAll(`.text-intro`);

      anime({
        easing: "easeOutQuad",
        targets: ".flower-right, .flower-left",
        opacity: ["0%", "100%"],
        duration: 1200,
        delay: 700,
      });

      anime({
        targets: ".vertical-shine",
        opacity: ["0%", "95%"],
        duration: 1300,
        delay: 1000,
      });

      anime({
        targets: ".horizontal-shine",
        opacity: ["0%", "95%"],
        duration: 2000,
        delay: 1300,
      });

      anime
        .timeline({ easing: "easeOutQuad" })
        .add({
          targets: ".bg-text-intro",
          opacity: ["0%", "100%"],
          duration: 1000,
          delay: 1800,
        })
        .add(
          {
            targets: textElements[0],
            opacity: ["0%", "100%"],
            duration: 900,
          },
          "-=500"
        )
        .add(
          {
            targets: textElements[1],
            opacity: ["0%", "100%"],
            duration: 900,
          },
          "-=200"
        )
        .add(
          {
            targets: ".shine-intro",
            opacity: ["0%", "100%"],
            duration: 200,
          },
          "-=1300"
        )
        .add(
          {
            targets: ".intro-sticker",
            opacity: ["0%", "100%"],
            duration: 900,
            delay: 200,
          },
          "-=100"
        )
        .add(
          {
            targets: ".place-sticker",
            opacity: ["0%", "100%"],
            duration: 900,
            delay: 0,
          },
          "-=100"
        )
        .add({
          targets: ".intro-button",
          opacity: ["0%", "100%"],
          duration: 400,
          delay: 200,
        });
    },
  },
};
</script>

<style scoped>
.z-index-top {
  z-index: 1000;
}
.translate-3d {
  transform: translate3d(0, 0, 0);
}
.animation-origin {
  transform: translate3d(0, 0, 0) rotate(12deg) scale(0.7);
}

.animation-end {
  transform: translate3d(0, 0, 0) rotate(0deg) scale(1);
}

.bg-image {
  background-image: url("../assets/background.jpg");
  background-size: cover;
}

.bg-color {
  background-color: #54a3e1;
}

.vertical-shine {
  height: 65vh;
}

.blend-screen {
  mix-blend-mode: screen;
}

.transition-height {
  transition: height 200ms !important;
}

@media screen and (min-width: 640px) {
  .vertical-shine {
    height: 110vh;
  }
}

.intro-sticker svg {
  transform: translateX(12vw) translateY(17vw) rotate(12deg);
}

@media screen and (min-width: 778px) {
  .intro-sticker svg {
    transform: translateX(250px) translateY(180px) rotate(12deg);
  }
}

.place-sticker {
  margin-left: 8vw;
  margin-top: calc(45vh - 14rem);
  transform: rotate(-10deg);
  will-change: opacity;
}

@screen sm {
  .place-sticker {
    margin-left: calc(40vw - 16rem);
    margin-top: calc(50vh - 17rem);
  }
}

@screen md {
  .place-sticker {
    margin-left: calc(40vw - 23rem);
  }
}

@media screen and (min-height: 600px) {
  .show-bigger-height {
    display: block;
  }
}

@media screen and (min-height: 600px) {
  .comment-form-margin {
    margin-bottom: 5rem;
  }
}

.generalitat-logo-end {
  height: 1.75rem;
}

@screen sm {
  .generalitat-logo-end {
    @apply h-10;
  }
}
</style>
