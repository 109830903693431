<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.$i18n.locale);
  },
};
</script>
<style>
html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
}
</style>
