<template>
  <ul
    class="flex flex-col justify-center items-center space-y-2 px-6 py-6 text-sm sm:text-base pointer-events-none"
  >
    <li v-for="locale in localesNotActive" :key="locale" class="xl:hidden">
      <button
        @click="changeLocale(locale)"
        class="w-14 py-1 uppercase border border-white rounded-full hover:bg-white hover:text-blue-600 transition duration-300 ease-in-out focus:outline-none pointer-events-auto"
      >
        {{ locale }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    localesNotActive() {
      return this.$i18n.availableLocales.filter(
        (locale) => locale != this.$i18n.locale
      );
    },
  },
  methods: {
    changeLocale(locale) {
      this.$parent.$emit("reload", locale);
    },
  },
};
</script>

<style scoped>
.w-14 {
  width: 3.5rem;
}
</style>
