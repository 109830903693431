<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form
      action=""
      @submit.prevent="handleSubmit(sendVote)"
      novalidate
      class="flex flex-col"
    >
      <!--  v-slot="{ errors }" -->
      <ValidationProvider v-slot="{ errors }" tag="div" class="relative">
        <span class="absolute top-0 right-0 text-xs sm:text-sm -mt-6">{{
          $t("form.comment.length_warning")
        }}</span>
        <textarea
          class="block px-4 py-3 sm:px-8 sm:py-6 text-sm sm:text-lg w-full max-w-full resize-none bg-transparent border border-white border-opacity-25 placeholder-white outline-none hover:border-opacity-50 focus:border-opacity-100 transition duration-300 ease-in-out"
          name="Comment"
          :placeholder="$t('form.comment.explanation')"
          id="comment"
          v-model="inputs.comment"
          required
          cols="30"
          rows="6"
          maxlength="250"
          minlength="4"
        ></textarea>

        <span
          class="absolute bottom-0 right-0 mb-2 mr-2 text-xs text-red-100"
          style="touch-action: none; pointer-events: none"
          >{{ errors[0] }}</span
        >
      </ValidationProvider>
      <!--  v-slot="{ errors }" -->
      <div class="flex flex-col sm:flex-row margin-fix-border">
        <ValidationProvider
          v-slot="{ errors }"
          rules="numeric|between:0,150"
          tag="div"
          class="relative flex-1"
        >
          <input
            class="block px-4 py-3 sm:px-8 sm:py-6 text-sm sm:text-base w-full bg-transparent border border-white border-opacity-25 placeholder-white outline-none hover:border-opacity-50 focus:border-opacity-100 transition duration-300 ease-in-out"
            type="text"
            name="Age"
            :placeholder="$t('form.age.explanation')"
            required
            id="age"
            v-model="inputs.age"
            inputmode="numeric"
            pattern="[0-9]*"
          />

          <span
            class="absolute bottom-0 right-0 mb-2 mr-2 text-xs text-red-100"
            style="touch-action: none; pointer-events: none"
            >{{ errors[0] }}</span
          >
        </ValidationProvider>

        <button
          type="submit"
          class="relative py-3 flex-1 bg-transparent text-sm sm:text-base text-white text-opacity-75 border border-t-0 sm:border-l-0 sm:border-t border-white border-opacity-25 hover:bg-white hover:text-blue-500 transition duration-500 ease-in-out focus:outline-none"
        >
          {{ $t("form.send") }}
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import voteService from "@/services/voteService";
import i18n from "../i18n.js";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from "vee-validate";

import { required, between, numeric } from "vee-validate/dist/rules";

setInteractionMode("eager");
extend("required", {
  ...required,
  message: () => i18n.t("form.validations.required"),
});
extend("numeric", {
  ...numeric,
  message: () => i18n.t("form.validations.numeric"),
});
extend("between", {
  ...between,
  message: () => i18n.t("form.validations.between"),
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      inputs: {
        age: "",
        comment: "",
      },
    };
  },
  methods: {
    async sendVote() {
      let response;
      try {
        response = await voteService.postVote({
          user: localStorage.getItem("id"),
          age: this.inputs.age,
          answer: this.inputs.comment,
          timestamp: new Date().toISOString(),
        });
        localStorage.setItem("userVoted", true);
        this.$emit("step");
        // this.$store.commit("setVoted", true);
        console.log(response);
      } catch (error) {
        console.error(error);
        this.$emit("step");
      }
    },
  },
};
</script>

<style scoped>
/* textarea {
  transition: border 0.2s ease-in-out;
} */
textarea {
  border-bottom-color: rgba(255, 255, 255, 0);
}
textarea:hover {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}
textarea:focus {
  border-bottom-color: rgba(255, 255, 255, 1);
}

.margin-fix-border {
  margin-top: -0.05rem;
}
</style>
