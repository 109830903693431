<template>
  <div class="bubbles">
    <div class="bubble bubble-anim-1 absolute h-20 w-20">
      <img src="../assets/bubble.png" alt srcset />
    </div>
    <div class="bubble bubble-anim-1 absolute h-40 w-40 z-30">
      <img src="../assets/bubble.png" alt srcset />
    </div>
    <div class="bubble bubble-anim-1 absolute h-32 w-32">
      <img src="../assets/bubble.png" alt srcset />
    </div>
    <div class="bubble bubble-anim-2 absolute h-16 w-16">
      <img src="../assets/bubble.png" alt srcset />
    </div>
    <div class="bubble bubble-anim-2 absolute h-24 w-24">
      <img src="../assets/bubble.png" alt srcset />
    </div>
    <div class="bubble bubble-anim-2 absolute h-20 w-20 z-30">
      <img src="../assets/bubble.png" alt srcset />
    </div>
  </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export default {
  mounted() {
    this.bubbleAnimation("bubble-anim-1");
    setTimeout(() => {
      this.bubbleAnimation("bubble-anim-2");
    }, 18000);
  },
  methods: {
    bubbleAnimation(selector) {
      if (!selector) {
        console.warn("You must give the bubbleAnimation a class selector");
        return;
      }
      const bubblesToAnimate = document.getElementsByClassName(selector);
      bubblesToAnimate.forEach((element) => {
        element.style.transform = `translateX(${getRandomArbitrary(
          -window.innerWidth / 2 + 200,
          window.innerWidth / 2 - 200
        )}px)`;
      });

      anime({
        targets: bubblesToAnimate,
        easing: "linear",
        translateY: () => {
          return [0, `-${window.innerHeight + 500}`];
        },
        translateX: () => {
          return `+=${anime.random(
            -window.innerWidth / 2.5,
            window.innerWidth / 2.5
          )}`;
        },
        scale: () => {
          return anime.random(10, 15) / 10;
        },
        rotate: () => {
          return `+=${anime.random(-90, 90)}`;
        },
        duration: () => {
          return anime.random(18000, 30000);
        },
        delay: (el, i) => {
          return anime.random(5000, 10000) * i + 2000;
        },
        loop: true,
      });
    },
  },
};
</script>

<style>
.bubble {
  left: 50%;
  bottom: -12rem;
}
</style>
